export enum ShiftStatusEnum {
  AVAILABLE = 'available',
  AVAILABLE_NEW = 'available-new',
  AGENT_FINISHED = 'agent-finished',
  FINISHED = 'finished',
  FULL = 'full',
  NOT_AVAILABLE = 'not-available',
  ONGOING = 'ongoing',
  OPEN = 'open',
  SCHEDULED = 'scheduled',
  FULLY_BOOKED = 'fully-booked',
  RECOMMENDED = 'recommended',
}

export type ShiftStatus =
  | ShiftStatusEnum.AVAILABLE
  | ShiftStatusEnum.AVAILABLE_NEW
  | ShiftStatusEnum.AGENT_FINISHED
  | ShiftStatusEnum.FINISHED
  | ShiftStatusEnum.FULL
  | ShiftStatusEnum.NOT_AVAILABLE
  | ShiftStatusEnum.ONGOING
  | ShiftStatusEnum.OPEN
  | ShiftStatusEnum.SCHEDULED
  | ShiftStatusEnum.FULLY_BOOKED
  | ShiftStatusEnum.RECOMMENDED;

type BaseShiftStatus = Exclude<ShiftStatus, ShiftStatusEnum.FINISHED>;

export const BASE_SHIFT_STATUS_LABELS: Record<BaseShiftStatus, string> = {
  [ShiftStatusEnum.AVAILABLE]: 'Available',
  [ShiftStatusEnum.AVAILABLE_NEW]: 'Available',
  [ShiftStatusEnum.AGENT_FINISHED]: 'Finished',
  [ShiftStatusEnum.FULL]: 'Full',
  [ShiftStatusEnum.NOT_AVAILABLE]: 'Not Available',
  [ShiftStatusEnum.ONGOING]: 'Not Available',
  [ShiftStatusEnum.OPEN]: 'Open',
  [ShiftStatusEnum.SCHEDULED]: 'Committed',
  [ShiftStatusEnum.FULLY_BOOKED]: 'Fully Booked',
  [ShiftStatusEnum.RECOMMENDED]: 'Recommended',
};

export * from './Header';
export * from './Schedule';
